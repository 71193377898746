import DyteClient from "@dytesdk/web-core";
import { EventEmitter } from "events";
import { MeetingConfig } from "../types";
import { DyteController } from "./DyteController";
import Sockrates from "@dyte-in/sockrates-client";
declare global {
    interface Window {
        triggerDyteRecording: boolean;
        dyteRecording?: {
            getParticipantCount: () => number;
            isMeetingEnded: () => boolean;
            isMeetingJoined: () => boolean;
            getMeeting: () => DyteClient;
        }
    }
}

const SECOND = 1000;
const MINUTE = 60 * SECOND;

const SCAN_INTERVAL = 1 * SECOND;

export default class RecordingController extends EventEmitter {
    #emptyTime: Date | null = null;

    #meetingEnded: boolean = false;

    #meetingJoined: boolean = false;

    #scanInterval: NodeJS.Timer | null = null;

    #waitTimeMs: number = 1 * MINUTE;

    #sockrates?: Sockrates;

    constructor(private meeting: DyteController, private config: MeetingConfig) {
        super();
        this.#setup();
    }

    #setup(): void {
        this.#waitTimeMs = this.config.waitTimeMs;

        if (window.dyteRecording !== undefined) {
            throw new Error("Listeners on window have already been set!")
        }

        // Expose some helper functions on window for recorder to access meeting state
        window.dyteRecording = {
            getParticipantCount: () => {
                return this.getParticipantCount();
            },
            isMeetingEnded: () => {
                return this.#meetingEnded;
            },
            isMeetingJoined: () => {
                return this.#meetingJoined;
            },
            getMeeting: () => {
                return this.meeting?.getMeeting();
            },
        }

        // We scan meeting state when participants join, leave, and also peroidically (just in case we have a race while initial joining)
        this.meeting.handleParticipantJoin(() => {
            this.scanMeetingState();
            this.emit("participantCount", this.getParticipantCount());
        });

        this.meeting.handleParticipantLeave(() => {
            this.scanMeetingState();
            this.emit("participantCount", this.getParticipantCount());
        });

        this.meeting.handleMeetingJoined(() => {
            this.#meetingJoined = true;
            this.emit("participantCount", this.getParticipantCount());
        });

        this.meeting.handleMeetingLeft(( { state } ) => {
            if (state !== "ended") return;
            this.emit("meetingEnded");
            this.signalRecordingWorker(JSON.stringify({ type: "StopRecording", meetingId: this.meeting.getMeeting().meta.meetingId }));
            this.cleanup();
            window.triggerDyteRecording = false;
        });
    
        this.#scanInterval = setInterval(() => {
            this.scanMeetingState();
        }, SCAN_INTERVAL);
    }

    cleanup(): void {
        delete window.dyteRecording
        this.meeting.removeAllListeners();
        this.removeAllListeners();

        if (this.#scanInterval !== null) {
            clearInterval(this.#scanInterval);
        }
    }

    getParticipantCount() {
        return this.meeting.getParticipantCount();
    }

    getWaitlistedCount() {
        return this.meeting.getWaitlistedCount();
    }

    scanMeetingState(): void {
        // Length must be 0 if only recorder is in the meeting
        if (this.getParticipantCount() > 0 || this.getWaitlistedCount() > 0) {
            this.#emptyTime = null;
            this.#meetingEnded = false;
            window.triggerDyteRecording = true;
            return;
        }

        if (this.#emptyTime === null) {
            this.#emptyTime = new Date();
            return;
        }

        const timeDiff = (new Date().getTime() - this.#emptyTime.getTime());

        // Check if a minute has elapsed since the meeting was empty
        if (timeDiff > this.#waitTimeMs) {
            this.#meetingEnded = true;
            window.triggerDyteRecording = false;
            return;
        }

        this.#meetingEnded = false;
        window.triggerDyteRecording = true;
    }

    setRecorderWorkerWs(sockrates:Sockrates | null){
        if (sockrates){
            this.#sockrates = sockrates;
        }
    }

    async signalRecordingWorker(message:string){
       try{
             this.#sockrates?.sendRaw(message);
       }catch (err){
            console.error(err);
       }

    }

}